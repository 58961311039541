import React from "react"
import { Pie } from "react-chartjs-2"
import HeaderCard from "../components/headerCard/headerCard"
import "./styles.less"

const data = {
  labels: ["Bóng đá", "Bơi", "Bóng rổ", "Cờ"],
  datasets: [
    {
      label: "# of Votes",
      data: [12, 19, 3, 5],
      backgroundColor: [
        "rgba(255, 99, 132, 0.2)",
        "rgba(54, 162, 235, 0.2)",
        "rgba(255, 206, 86, 0.2)",
        "rgba(75, 192, 192, 0.2)"
      ],
      borderColor: [
        "rgba(255, 99, 132, 1)",
        "rgba(54, 162, 235, 1)",
        "rgba(255, 206, 86, 1)",
        "rgba(75, 192, 192, 1)"
      ],
      borderWidth: 1
    }
  ]
}

const options = {
  plugins: {
    legend: {
      position: "bottom"
    }
  }
}

export default function PopularSports() {
  return (
    <div className="popular-sports-container bg-white">
      <HeaderCard label="Môn thể thao được quan tâm" />
      <Pie data={data} options={options} height="209" />
    </div>
  )
}
