import Client from "../client/Client"
const resource = "/api/v1"

const getArticles = (params) => {
  return Client.get(`${resource}/articles/`, { params })
}

const createArticle = (data) => {
  return Client.post(`${resource}/articles/`, data)
}

const updateArticle = (id, data) => {
  return Client.put(`${resource}/article/${id}/`, data)
}

const getDetailArticles = (id) => {
  return Client.get(`${resource}/article/${id}/`)
}

const getPublistCategories = () => {
  return Client.get(`${resource}/publish/categories/`)
}

const upadteStatus = (id, data) => {
  return Client.post(`${resource}/article/${id}/status/`, data)
}

const updateStaff = () => {
  return Client.put(`${resource}/categories/reorder/`)
}

const mapping = (screenBlockId, data) => {
  return Client.post(`${resource}/articles/${screenBlockId}/screenblock/`, data)
}

const getElasticSearch = (params) => {
  return Client.get(`${resource}/staffs/elastic-search/`, { params })
}

const pushToTop = (id) => {
  return Client.post(`${resource}/article/add-to-dashboard/${id}/`)
}

const getArticlesElasticSearch = (params) => {
  return Client.get(`${resource}/articles/elastic-search/`, { params })
}

const PermissionRepository = {
  getArticles,
  createArticle,
  updateArticle,
  getDetailArticles,
  getPublistCategories,
  upadteStatus,
  updateStaff,
  mapping,
  getElasticSearch,
  pushToTop,
  getArticlesElasticSearch
}
export default PermissionRepository
