import { Form, Input, Button, Card, Radio } from "antd"
import { UserOutlined, LockOutlined } from "@ant-design/icons"
import { apiUser } from "api"
import { useHistory } from "react-router-dom"
import BackgroundLogin from "images/background-image.png"
import Logo from "images/Logo.png"
import { useState } from "react"
import { setConfig } from "lib/localstorage"
import { useStore } from "components/ui"
const options = [
  { label: "Onnews", value: true }
  // { label: "OnTV", value: false }
]

const Login = () => {
  const { setAuth } = useStore()
  const history = useHistory()
  const [isOnNews, setOnnews] = useState(true)
  const [loading, setLoading] = useState(false)

  const onFinish = async (values) => {
    setLoading(true)
    try {
      let res = {}
      res = await apiUser.login(values)

      const { user, token, menu, logo_side_bar, permissions, base_link, refresh } = res
      // localStorage.setItem("accessToken", token)
      localStorage.setItem("isCheck", isOnNews)
      // localStorage.setItem("logoSideBar", logo_side_bar)
      // localStorage.setItem("menus", JSON.stringify(menu))
      // localStorage.setItem("permissions", JSON.stringify(permissions))
      // localStorage.setItem("user", JSON.stringify(user))
      // localStorage.setItem("expireDate", JSON.stringify(new Date().getTime()))
      // localStorage.setItem("base_link", JSON.stringify(base_link))
      setAuth({
        token,
        refresh,
        user: user,
        menus: menu,
        logo: logo_side_bar,
        permissions
      })
      setConfig("base_link", base_link)
      history.push("/article/list")
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  function onLogin() {
    let values = {
      username: "trangnth",
      password: "000000a"
    }
    onFinish(values)
  }
  const isDev =
    process.env.NODE_ENV === "development" || window.location.host === "cms.onsports-dev.com"

  return (
    <div>
      <img src={BackgroundLogin} alt="BackgroundLogin" className="w-full h-screen" />
      <div className="fixed w-full inset-0 flex items-center justify-center">
        <Card className="max-w-md w-full">
          <div className="flex justify-center mb-5">
            <img src={Logo} alt="Logo" />
          </div>
          <h1 className="text-center">Đăng Nhập</h1>
          <Form
            name="normal_login"
            className="login-form"
            initialValues={{ remember: true }}
            onFinish={onFinish}
          >
            <Form.Item name="username" rules={[{ required: true, message: "Hãy điền tài khoản!" }]}>
              <Input
                size="large"
                prefix={<UserOutlined className="site-form-item-icon" />}
                placeholder="Tài khoản"
              />
            </Form.Item>
            <Form.Item name="password" rules={[{ required: true, message: "Hãy điền mật khẩu!" }]}>
              <Input
                size="large"
                prefix={<LockOutlined className="site-form-item-icon" />}
                type="password"
                className="mb-8"
                placeholder="Mật khẩu"
              />
            </Form.Item>

            {isDev && (
              <div className="flex justify-center p-2">
                <Button onClick={onLogin}>DemoLogin</Button>
              </div>
            )}
            <Form.Item>
              <Button
                type="primary"
                size="large"
                htmlType="submit"
                className="w-full"
                loading={loading}
              >
                Đăng Nhập
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </div>
    </div>
  )
}

export default Login
