import Client from "../client/ClientSoccer"
const resource = "/api/v1"

const getSports = (params) => {
  return Client.get(`${resource}/publish/leagues/`, { params })
}

const getTeams = (params) => {
  return Client.get(`${resource}/teamprofile`, { params })
}
const SoccerRepository = {
  getSports,
  getTeams
}
export default SoccerRepository
