import React, { Suspense, Fragment, memo, useEffect } from "react"
import { Result, Card } from "antd"
import { BrowserRouter as Router, Route, Switch, Redirect, Link } from "react-router-dom"
import SpinnerLoadMenu from "./SpinnerLoadMenu"
import SuspenseComponent from "./SuspenseComponent"
import Login from "pages/auth/Login"
import Layout from "components/common/Layout"
import routerOnnews from "./RouterOnnews"
import { useStore } from "components/ui"

const getPermission = (menus, code) => {
  const menu = menus.find((i) => i.code === code)
  // const isTrue = action ? permissions.find(i => i === action) : true
  if (menu) {
    return [true, menu.tab]
  }
  return [false, []]
}

const ProtectedPage = ({ menus }) => {
  function WaitingComponent(Component, code) {
    const info = getPermission(menus, code)
    if (1) {
      return (props) => (
        <Suspense fallback={<SuspenseComponent />}>
          <Component {...props} tab={info[1]} />
        </Suspense>
      )
    } else {
      return NotLoadAuthorization
    }
  }
  return (
    <Fragment>
      {menus && menus.length > 0 && (
        <Layout>
          <Switch>
            {routerOnnews.map(({ component, path, code }, key) => {
              return (
                <Route
                  key={`${key}-on_news`}
                  path={path}
                  exact
                  component={WaitingComponent(component, code)}
                />
              )
            })}
            <Route component={NotLoadAuthorization} />
          </Switch>
        </Layout>
      )}
      <SpinnerLoadMenu isShow={!menus.length} />
    </Fragment>
  )
}

const Routes = () => {
  const { menus, token } = useStore()
  useEffect(() => {}, [])
  return (
    <Router>
      <Switch>
        <Route exact path="/login" component={Login} />
        {/* <Route exact path="/signup" component={SignUp} />
        <Route exact path="/verify" component={Verify} />
        <Route exact path="/forgot-password" component={ForgotPassword} />
        <Route exact path="/reset-password" component={ChangePassword} /> */}
        <Route path="/">{token ? <ProtectedPage menus={menus} /> : <Redirect to="/login" />}</Route>
      </Switch>
    </Router>
  )
}

export default memo(Routes)

const NotLoadAuthorization = () => {
  return (
    <Card>
      <Result
        status="404"
        title="404"
        subTitle="Sorry, the page you visited does not exist."
        extra={<Link to="/dashboard">Back Home</Link>}
      />
    </Card>
  )
}
