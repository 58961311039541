import "./styles.less"
import OverView from "./overView/overView"
import Events from "./events/event"
import Channels from "./channels/channels"
import RecentUsers from "./recentUsers/recentUsers"
import MostViewedCates from "./mostViewedCates/mostViewedCates"
import PopularSports from "./popularSports/popularSports"
import UpcommingMatches from "./upcommingMatches/upcommingMatches"

export default function Dashboard() {
  return (
    <div className="dashboard-page-index wapper_small">
      <OverView />
      <div className="dashboard-container statistic-charts-container">
        <RecentUsers />
        <MostViewedCates />
      </div>
      <div className="dashboard-container sports-container">
        <PopularSports />
        <UpcommingMatches />
      </div>
      <div className="dashboard-container events-channels-container">
        <Events />
        <Channels />
      </div>
    </div>
  )
}
