import React from "react"
import { Line } from "react-chartjs-2"
import HeaderCard from "../components/headerCard/headerCard"
import "./styles.less"

const data = {
  labels: ["1", "2", "3", "4", "5", "6"],
  datasets: [
    {
      label: "# of Votes",
      data: [12, 19, 3, 5, 2, 3],
      fill: false,
      backgroundColor: "blue",
      borderColor: "blue"
    }
  ]
}

const options = {
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true
        }
      }
    ]
  },
  plugins: {
    legend: {
      display: false
    }
  }
}

export default function RecentUsers() {
  return (
    <div className="recent-users-container">
      <HeaderCard label="Số lượng người dùng vào gần đây " />
      <Line data={data} options={options} />
    </div>
  )
}
