import React from "react"
import Dashboard from "pages/Dashboard"

const DemoView = React.lazy(() => import("pages/DemoView"))
const Permission = React.lazy(() => import("pages/Permission"))
const PermissionDetail = React.lazy(() => import("pages/Permission/PermissionDetail"))
const Tags = React.lazy(() => import("pages/Tags"))
const Pundit = React.lazy(() => import("pages/Pundit"))
const Customer = React.lazy(() => import("pages/Customer"))
const Categorie = React.lazy(() => import("pages/Categorie"))
const Profile = React.lazy(() => import("pages/Profile"))
const ArticlesList = React.lazy(() => import("pages/Articles/ArticlesList"))
const UpdateArticles = React.lazy(() => import("pages/Articles/UpdateArticles"))
const ScreenBlock = React.lazy(() => import("pages/ScreenBlock"))
const Preview = React.lazy(() => import("pages/Preview"))
const MediaManager = React.lazy(() => import("pages/MediaManager"))
const ArticleMapScreenBlock = React.lazy(() => import("pages/ArticleMapScreenBlock"))
const CmsSettings = React.lazy(() => import("pages/CmsSettings"))
const Event = React.lazy(() => import("pages/Event"))

const routerOnnews = [
  {
    component: Dashboard,
    path: "/dashboard",
    code: "HOME_CONTROLLER"
  },
  {
    component: ScreenBlock,
    path: "/screen-block",
    code: "SCREEN_BLOCK_CONTROLLER"
  },
  {
    component: DemoView,
    path: "/preview",
    code: "SCREEN_BLOCK_CONTROLLER"
  },
  {
    component: Permission,
    path: "/staff/:tab",
    code: "STAFF_CONTROLLER"
  },
  {
    component: PermissionDetail,
    path: "/staff/groups/:id/:name",
    code: "STAFF_CONTROLLER"
  },
  {
    component: Tags,
    path: "/tag",
    code: "TAG_CONTROLLER"
  },
  {
    component: Pundit,
    path: "/pundit",
    code: "PUNDIT_CONTROLLER"
  },
  {
    component: Customer,
    path: "/customer/list",
    code: "CUSTOMER_CONTROLLER"
  },
  {
    component: Categorie,
    path: "/category",
    code: "CATEGORY_CONTROLLER"
  },
  {
    component: UpdateArticles,
    path: "/article/create/:idArticle",
    code: "ARTICLE_CONTROLLER"
  },
  {
    component: ArticlesList,
    path: "/article/list",
    code: "ARTICLE_CONTROLLER"
  },
  {
    component: Preview,
    path: "/preview/:idArticle",
    code: "ARTICLE_CONTROLLER"
  },
  {
    component: ArticleMapScreenBlock,
    path: "/article-map-screenblock/:id",
    code: "ARTICLE_CONTROLLER"
  },
  {
    component: Profile,
    path: "/profile",
    code: "PROFILE"
  },
  {
    component: MediaManager,
    path: "/media",
    code: "MEDIA_CONTROLLER"
  },
  {
    component: CmsSettings,
    path: "/setting",
    code: "SETTING_CONTROLLER"
  },
  {
    component: Event,
    path: "/event/:tab",
    code: "EVENT_CONTROLLER"
  }
]
export default routerOnnews
