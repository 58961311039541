import React from "react"
import { Dropdown, Avatar } from "antd"
import { useHistory, Link } from "react-router-dom"
import { UserOutlined, LoginOutlined } from "@ant-design/icons"

const MyMenu = () => {
  const history = useHistory()

  function handleLogout() {
    localStorage.clear()
    history.push("/login")
  }

  return (
    <div className="p-4 shadow_antd space-y-4 bg-white rounded">
      <Link to="/profile" className="cursor-pointer text-slate-900 hover:text-primary">
        <UserOutlined /> &nbsp;Thông tin tài khoản
      </Link>

      <div onClick={handleLogout} className="cursor-pointer hover:text-primary">
        <LoginOutlined />
        &nbsp; Đăng xuất
      </div>
    </div>
  )
}

const Header = ({ user }) => {
  return (
    <div className="flex">
      <Dropdown overlay={<MyMenu />} placement="bottomLeft">
        <div className="cursor-pointer flex jutify-center items-center">
          <div className="flex items-center justify-center hover-sb mr-2">
            <Avatar size={30} onClick={(e) => e.preventDefault()} src={user?.avatar} />
          </div>
          <span className="user-name text-white">{user?.name}</span>
          <div className="h-6 w-6 hidden"></div>
        </div>
      </Dropdown>
    </div>
  )
}

export default Header
