import Client from "../client/Client"
const resource = "/api/v1/categories"

const getCategories = (params) => {
  return Client.get(`${resource}/`, { params })
}
const getCategoriesVisible = () => {
  return Client.get(`/api/v1/publish/categories/`)
}

const getDetailCategorie = (id) => {
  return Client.get(`${resource}/${id}/`)
}

const createCategorie = (data) => {
  return Client.post(`${resource}/`, data)
}

const updateCategorieStatus = (id, data) => {
  return Client.put(`${resource}/${id}/status/`, data)
}
const updateCategorie = (id, data) => {
  return Client.put(`${resource}/${id}/`, data)
}
const deleteCategorie = (id) => {
  return Client.delete(`${resource}/${id}/`)
}

const TagRepository = {
  getCategories,
  getDetailCategorie,
  createCategorie,
  updateCategorie,
  deleteCategorie,
  getCategoriesVisible,
  updateCategorieStatus
}
export default TagRepository
