import React from "react"
import "./styles.less"

export default function DashboardCard(props) {
  return (
    <div className="dashboard-card">
      <div className="card-icon" style={{ background: props.iconBackground, color: props.color }}>
        {props.icon}
      </div>
      <div className="card-info">
        <h4 className="number-info" style={{ color: props.color }}>
          {props.numberInfo}
        </h4>
        <p className="label-info">{props.numberLabel}</p>
      </div>
    </div>
  )
}
