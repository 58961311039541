import React from "react"
import { Bar } from "react-chartjs-2"
import HeaderCard from "../components/headerCard/headerCard"
import "./styles.less"

const data = {
  labels: ["Việt Nam", "Quốc tế", "Livescore", "Video", "eMagazine", "Nhận định"],
  datasets: [
    {
      label: "# of Votes",
      data: [12, 19, 3, 5, 2, 3],
      backgroundColor: [
        "rgba(255, 99, 132, 0.2)",
        "rgba(54, 162, 235, 0.2)",
        "rgba(255, 206, 86, 0.2)",
        "rgba(75, 192, 192, 0.2)",
        "rgba(153, 102, 255, 0.2)",
        "rgba(255, 159, 64, 0.2)"
      ],
      borderColor: [
        "rgba(255, 99, 132, 1)",
        "rgba(54, 162, 235, 1)",
        "rgba(255, 206, 86, 1)",
        "rgba(75, 192, 192, 1)",
        "rgba(153, 102, 255, 1)",
        "rgba(255, 159, 64, 1)"
      ],
      borderWidth: 1
    }
  ]
}

const options = {
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true
        }
      }
    ]
  },
  plugins: {
    legend: {
      display: false
    }
  }
}

export default function MostViewedCates() {
  return (
    <div className="most-viewed-container bg-white">
      <HeaderCard label="Danh mục bài viết được quan tâm" />
      <Bar data={data} options={options} />
    </div>
  )
}
