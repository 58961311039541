import Client from "../client/Client"
const resource = "/api/v1"

const getEvents = (params) => {
  return Client.get(`${resource}/events/`, { params })
}

const createEvent = (data) => {
  return Client.post(`${resource}/events/`, data)
}

const updateEvent = (eventId, data) => {
  return Client.put(`${resource}/event/${eventId}/`, data)
}

const getChannel = (params) => {
  return Client.get(`${resource}/channels/`, { params })
}

const createChannel = (data) => {
  return Client.post(`${resource}/channels/`, data)
}

const TagRepository = {
  getEvents,
  createEvent,
  updateEvent,
  getChannel,
  createChannel
}
export default TagRepository
