import Client from "../client/Client"
const resource = "/api/v1"

const getPundits = (params) => {
  return Client.get(`${resource}/pundits/`, { params })
}

const getDetailPundit = (punditId) => {
  return Client.get(`${resource}/pundit/${punditId}/`)
}

const createPundit = (data) => {
  return Client.post(`${resource}/pundits/`, data)
}

const updatePundit = (data, punditId) => {
  return Client.put(`${resource}/pundit/${punditId}/`, data)
}

const deletePundit = (punditId) => {
  return Client.delete(`${resource}/pundit/${punditId}/`)
}

const TagRepository = {
  getPundits,
  createPundit,
  updatePundit,
  deletePundit,
  getDetailPundit
}
export default TagRepository
