const key = {
  SET_AUTH: "SET_AUTH",
  LOG_OUT: "LOG_OUT",
  SET_CONFIG_TYPE: "SET_CONFIG_TYPE",
  SET_CONFIG_AGE: "SET_CONFIG_AGE",
  SET_CONFIG_CATEGORY: "SET_CONFIG_CATEGORY",
  SET_CHANNEL: "SET_CHANNEL",
  SET_BLOCK: "SET_BLOCK",
  SET_LEAGUE: "SET_LEAGUE",
  CALLING: "CALLING"
}

export default key
