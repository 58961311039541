import Client from "../client/Client"
const resource = "/api/v1"

const getCustomers = (params) => {
  return Client.get(`${resource}/customers/`, { params })
}

const TagRepository = {
  getCustomers
}
export default TagRepository
