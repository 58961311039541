import Client from "../client/Client"
const resource = "/api/v1/screen-blocks"

const getScreenBlock = (params) => {
  return Client.get(`${resource}/`, { params })
}
const createScreenBlock = (data) => {
  return Client.post(`${resource}/`, data)
}
const updateScreenBlock = (id, data) => {
  return Client.put(`/api/v1/screen-block/${id}/`, data)
}
const updateOrderScreenBlock = (data) => {
  return Client.put(`${resource}/order/`, data)
}
const getTypeScreenBlock = () => {
  return Client.get(`${resource}/type/`)
}
const getDetailScreenBlock = (id) => {
  return Client.get(`/api/v1/screen-block/${id}/`)
}
const deleteScreenBlock = (id) => {
  return Client.delete(`/api/v1/screen-block/${id}/`)
}

const PermissionRepository = {
  getScreenBlock,
  createScreenBlock,
  updateScreenBlock,
  getTypeScreenBlock,
  deleteScreenBlock,
  getDetailScreenBlock,
  updateOrderScreenBlock
}
export default PermissionRepository
