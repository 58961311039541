import "./styles.less"
import HeaderCard from "../components/headerCard/headerCard"
import { Table } from "components/ui"
import { useState } from "react"

const columns = () => {
  return [
    {
      key: "name",
      dataIndex: "name",
      title: "Tên",
      render: (name) => {
        return <p className="primaryCell">{name}</p>
      }
    },
    {
      key: "address",
      dataIndex: "address",
      title: "Địa điểm",
      render: (address) => {
        return <p className="normalCell">{address}</p>
      }
    },
    {
      key: "time",
      dataIndex: "time",
      title: "Thời gian",
      render: (time) => {
        return <p className="normalCell">{time}</p>
      }
    }
  ]
}

export default function Events() {
  const [data] = useState([
    {
      name: "Bình luận trận đấu Việt Nam - Myanma",
      address: "Trường quay S1",
      time: "08:00 - 10/05/2021"
    },
    {
      name: "Bình luận trận đấu Việt Nam - Myanma",
      address: "Trường quay S1",
      time: "08:00 - 10/05/2021"
    },
    {
      name: "Bình luận trận đấu Việt Nam - Myanma",
      address: "Trường quay S1",
      time: "08:00 - 10/05/2021"
    },
    {
      name: "Bình luận trận đấu Việt Nam - Myanma",
      address: "Trường quay S1",
      time: "08:00 - 10/05/2021"
    },
    {
      name: "Bình luận trận đấu Việt Nam - Myanma",
      address: "Trường quay S1",
      time: "08:00 - 10/05/2021"
    },
    {
      name: "Bình luận trận đấu Việt Nam - Myanma",
      address: "Trường quay S1",
      time: "08:00 - 10/05/2021"
    },
    {
      name: "Bình luận trận đấu Việt Nam - Myanma",
      address: "Trường quay S1",
      time: "08:00 - 10/05/2021"
    },
    {
      name: "Bình luận trận đấu Việt Nam - Myanma",
      address: "Trường quay S1",
      time: "08:00 - 10/05/2021"
    },
    {
      name: "Bình luận trận đấu Việt Nam - Myanma",
      address: "Trường quay S1",
      time: "08:00 - 10/05/2021"
    }
  ])

  return (
    <div className="events-container bg-white">
      <HeaderCard label="Sự kiện sắp diễn ra" />
      <Table columns={columns()} dataSource={data} bordered={false} scroll={{ y: 240 }} />
    </div>
  )
}
