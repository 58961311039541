import Client from "../client/Client"
const resource = "/api/v1"

// const signUp = (data) => {
//   return Client.post(`/auth/register/`, data)
// }

const login = (data) => {
  return Client.post(`${resource}/authentications/login/`, data)
}

const getInfo = () => {
  return Client.get(`${resource}/account/information/`)
}

const changePassword = (data) => {
  return Client.put(`${resource}/account/password/`, data)
}

const userRepository = {
  login,
  getInfo,
  changePassword
}
export default userRepository
