import React from "react"
import "./styles.less"
import FilterDashboard from "../filterDashboard/filterDashboard"

export default function HeaderCard(props) {
  return (
    <div className="header-card-dashboard">
      <h4 className="card-label">{props.label}</h4>
      <FilterDashboard />
    </div>
  )
}
