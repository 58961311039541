import "./styles.less"
import HeaderCard from "../components/headerCard/headerCard"
import HN from "./HN.png"
import HAGL from "./HAGL.png"
import { useState } from "react"

export default function UpcommingMatches() {
  const [array] = useState([1, 2, 3, 4, 5])
  return (
    <div className="upcomming-matches-container bg-white">
      <HeaderCard label="Các trận đấu sắp diễn ra" />
      <div>
        {array.map((item, index) => (
          <div className="grid grid-cols-10 gap-4 mt-4">
            <div class="col-span-2">
              <p className="start-time">22:00</p>
              <p>03/05/2021</p>
            </div>
            <div class="col-span-2">
              <p>Sân vận động Mỹ Đình</p>
            </div>
            <div class="col-span-3 flex items-start justify-center">
              <div className="matches-logos">
                <img alt="thumbnail" src={HN} height="48" width="48" />
                <p className="vs-label">VS</p>
                <img alt="thumbnail" src={HAGL} height="48" width="48" />
              </div>
            </div>
            <div class="col-span-1">
              <p>Hà Nội</p>
              <p className="bet-rate">2.10</p>
            </div>
            <div class="col-span-1">
              <p>Hoà</p>
              <p className="bet-rate">2.10</p>
            </div>
            <div class="col-span-1">
              <p>HAGL</p>
              <p className="bet-rate">2.10</p>
            </div>
            {index < array.length - 1 && <div class="col-span-10" style={{ borderWidth: 1 }}></div>}
          </div>
        ))}
      </div>
    </div>
  )
}
