import React from "react"
import { Select } from "antd"
import { FilterOutlined } from "@ant-design/icons"
const { Option } = Select
export default function FilterDashboard(props) {
  // const [filteredOption, setFilteredOption] = React.useState("1")

  // const handleChange = (v) => {
  //   setFilteredOption(v)
  //   console.log(v)
  // }

  return (
    <div className="flex space-x-4 items-center">
      <div className="flex items-center">
        {props.showIcon ? <FilterOutlined /> : null}
        <p className="filter-label m-0">Lọc theo: </p>
      </div>
      <Select defaultValue="1" style={{ width: 120 }}>
        <Option value="1">1 tháng</Option>
        <Option value="2">2 tháng</Option>
        <Option value="3">3 tháng</Option>
      </Select>
    </div>
  )
}
