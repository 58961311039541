import { TitlePage } from "components/ui"
import React from "react"
import DashboardCard from "../components/dashboardCard/dashboardCard"
import FilterDashboard from "../components/filterDashboard/filterDashboard"
import "./styles.less"

export default function OverView() {
  return (
    <section className="wapper_small">
      <div className="title-container flex justify-between">
        <TitlePage title="Tổng quan" />
        {/* <h1 className="page-title">TỔNG QUAN</h1> */}
        <FilterDashboard showIcon={true} />
      </div>
      <div className="cards-container flex justify-between">
        <DashboardCard
          numberInfo="10.000.000"
          numberLabel="lượt truy cập trang"
          color="#36B37E"
          iconBackground="#36b37e33"
          icon={iconPeople}
        ></DashboardCard>
        <DashboardCard
          numberInfo="2000"
          numberLabel="bài viết được xuất bản"
          color="#0098D9"
          iconBackground="#0098d933"
          icon={iconPost}
        ></DashboardCard>
        <DashboardCard
          numberInfo="500"
          numberLabel="sự kiện đã diễn ra"
          color="#6554C0"
          iconBackground="#6554c033"
          icon={calendar}
        ></DashboardCard>
      </div>
    </section>
  )
}

const iconPeople = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    class="h-8 w-8"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      strokeLinejoin="round"
      stroke-width="2"
      d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"
    />
  </svg>
)
const iconPost = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    class="h-8 w-8"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      strokeLinejoin="round"
      stroke-width="2"
      d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01"
    />
  </svg>
)

const calendar = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    class="h-8 w-8"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      strokeLinejoin="round"
      stroke-width="2"
      d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
    />
  </svg>
)
