import "./styles.less"
import HeaderCard from "../components/headerCard/headerCard"
import logo_channels from "./logo_channels.png"

import { Table } from "components/ui"
import { useState } from "react"
import { Tag } from "antd"

const columns = () => {
  return [
    {
      key: "thumbnail",
      dataIndex: "thumbnail",
      title: "Thumbnail",
      render: (thumbnail) => {
        return <img alt="thumbnail" src={thumbnail} height="40" />
      }
    },
    { key: "name", dataIndex: "name", title: "Display name" },
    {
      key: "status",
      dataIndex: "status",
      title: "Status",
      render: (status) => {
        return (
          <Tag style={{ borderRadius: 10 }} color={status === "Published" ? "#0098D9" : "#FFAB00"}>
            <p className={status}>{status}</p>
          </Tag>
        )
      }
    },
    {
      key: "statusChannel",
      dataIndex: "statusChannel",
      title: "Status Channel",
      render: (statusChannel) => {
        return (
          <Tag
            style={{ borderRadius: 10 }}
            color={statusChannel === "Active" ? "green" : "default"}
          >
            <p className={statusChannel}>{statusChannel}</p>
          </Tag>
        )
      }
    }
  ]
}

export default function Channels() {
  const [data] = useState([
    {
      thumbnail: logo_channels,
      name: "Bóng đá TV",
      status: "Draft",
      statusChannel: "Active"
    },
    {
      thumbnail: logo_channels,
      name: "Bóng đá TV",
      status: "Published",
      statusChannel: "Off"
    },
    {
      thumbnail: logo_channels,
      name: "Bóng đá TV",
      status: "Draft",
      statusChannel: "Active"
    },
    {
      thumbnail: logo_channels,
      name: "Bóng đá TV",
      status: "Published",
      statusChannel: "Active"
    }
  ])

  return (
    <div className="channels-container">
      <HeaderCard label="Kênh đang phát sóng" />
      <Table columns={columns()} dataSource={data} bordered={false} />
    </div>
  )
}
